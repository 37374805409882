import AOS from 'aos';

jQuery( document ).ready(function($) {

  // ANIMATIONS
  AOS.init({
    duration: 1000,
    once: true,
  });

  // MOBILE MENU
  $('.menu-trigger, .nav li a').click(function(){
    $('.menu').toggleClass('active')
    $('.menu-trigger').toggleClass('active')
  })

  // IG FEED
  $.ajax( {
		url: 'https://www.juicer.io/api/feeds/josvanii',
		method: 'GET',
		dataType: 'json',
		success: data => {
			let html = '';
			let i = 0;
			for ( let item of data.posts.items ) {
				html += '<div>';
				html += '<a href="' + item.external_url + '" target="_blank">';
				html += '<img src="' + item.image + '" alt="' + item.id + '" />';
				html += '</a>';
				html += '</div>';

				i ++;
				if ( i >= 8 ) {
					break;
				}
			}

			$( '#instagram-posts' ).html( html );
		},
		error: () => {
			alert( 'Error fetching Instagram posts!' );
		}
	} );

	$.ajax( {
		url: 'https://rest.bandsintown.com/artists/JOSVANI/events?app_id=45PRESS_JOSVANI',
		method: 'GET',
		dataType: 'json',
		error: () => {
			alert( 'Error fetching events!' );
		},
		success: data => {
		const events = $( '#events' );
		let html = '';
		if ( data.length ) {
			for ( let event of data ) {
				let location = event.venue.city + ', ' + event.venue.region;
				if ( location === ', ' ) {location = '';}
				if ( event.venue.name.toLowerCase() === 'streaming live' ) {location = 'Online';}
				html += '<div class="event">';
				html += '<div class="event-date">' + momemt( event.datetime ).format( 'MMM D' ) + '</div>';
				html += '<div class="event-location">' + location + '</div>';
				html += '<div class="event-venue">' + event.venue.name + '</div>';
				for ( let offer of event.offers ) {
					html += '<a href="' + offer.url + '" target="_blank" class="btn btn-yellow">' + offer.type + '</a>';
				}
				html += '</div>';
			}
			events.html( html );
			} else {
				events.html( 'No upcoming events.' );
			}
		}
	});

});